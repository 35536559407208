const fade = (hex, opacity) => {
  const hexRGB = hex.replace('#', '');
  const r = parseInt(hexRGB.substring(0, 2), 16);
  const g = parseInt(hexRGB.substring(2, 4), 16);
  const b = parseInt(hexRGB.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity || 1})`;
};

export default {
  fade,
};
