// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-echipament-js": () => import("./../../../src/pages/echipament.js" /* webpackChunkName: "component---src-pages-echipament-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portofoliu-index-js": () => import("./../../../src/pages/portofoliu/index.js" /* webpackChunkName: "component---src-pages-portofoliu-index-js" */),
  "component---src-pages-portofoliu-poze-js": () => import("./../../../src/pages/portofoliu/poze.js" /* webpackChunkName: "component---src-pages-portofoliu-poze-js" */),
  "component---src-pages-termeni-si-conditii-js": () => import("./../../../src/pages/termeni-si-conditii.js" /* webpackChunkName: "component---src-pages-termeni-si-conditii-js" */)
}

