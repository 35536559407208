const typography = typo => ({
  h1: {
    fontSize: typo.pxToRem(48),
    lineHeight: 1.1,
    fontWeight: 800,
  },
  h2: {
    fontSize: typo.pxToRem(30),
    lineHeight: 1.1,
    fontWeight: 800,
  },
  h3: {
    fontSize: typo.pxToRem(28),
    lineHeight: 1.1,
    fontWeight: 300,
  },
  body1: {
    fontSize: typo.pxToRem(16),
    lineHeight: 1.1,
    fontWeight: 300,
  },
  body2: {
    fontSize: typo.pxToRem(16),
    lineHeight: 1.1,
    fontWeight: 300,
  },
});

export default typography;
