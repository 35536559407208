const palette = {
  background: {
    default: '#ffffff',
  },
  text: {
    primary: '#17252A',
    secondary: '#b9bebf',
  },
  common: {
    white: '#ffffff',
  },
  color: {
    paleGray: '#F4F6F8',
    paleGreen: '#d3e2b7',
  },
  primary: {
    light: '#d3e2b7',
    main: '#6b9e10',
    dark: '#4e8209',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#b9bebf',
    main: '#17252A',
    dark: '#0d1619',
    contrastText: '#ffffff',
  },
};

export default palette;
