import { TOGGLE_MENU } from './constants';
const initialState = {
  open: false,
};

function navReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return { ...state, open: !state.open };
    default:
      return state;
  }
}

export default navReducer;
